import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { SliderRail, Handle, Track, Tick } from './ChopSliderComponent';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import PropTypes from 'prop-types';
import StyledField, {StyledFieldWrapper} from '../styled-components/ChopStyledField'

/*const StyledField = styled.input`
    border: 2px solid #5E3D22;
    border-radius: 5px;
    background-color: #FFFFFC;
    padding: 0.7em 0.5em;
    margin: 2px;
`;*/
const Cf = (props) => {

    const [value, setValue] = useState( () => props && !!props.value ? props.value : 5 )
    const [valueWithCommas, setValueWithCommas] = useState("")
    const [barPos, setBarPos] = useState( () => props && !!props.value ? props.value : 5 )
    const [step, setStep] = useState(1)
    const [domain, setDomain] = useState(() => props && !!props.domain ? [0,Math.floor((props.domain[1]/10) *10)] : [0,10])
    const [isFocused, setFocused] = useState(false)

    // refs
    const inputRef = useRef()

    const setPropValue = (newValue) => {
        //console.log("setPropValue", newValue, Math.floor((props.domain[1]/10) *10))
        props.update(newValue)
    }

    const changeValue = (newValue) => {

        // if new value has a letter, don't update
        if ( !/[a-zA-Z]/g.test(newValue)) {
            //console.log("new value: ", newValue, typeof newValue, props.domain )
            if (newValue === "") setValue(newValue); // user backspaced
            else if (newValue > props.domain[1]) newValue = props.domain[1]
            else if (newValue < props.domain[0]) newValue = props.domain[0]
            props.update(newValue)
        } else {
            console.log("Received bad value: ", newValue)
        }
    }

    /**
     * Check if step !== 10 and if not, change the value.
     * TODO: figure out how to adjust step without messing up if user types
     */
    const changeValueSlider = (newValue) => {

        /*
        if ( step !== 10 ) {
            newValue = Math.round( (newValue / 10) * 10 )
            setStep(10)
        }*/


        setValue(newValue)

        //props.update(newValue)
    }

        /**
     * Focused changed. If selected, highlight all text
     */
    useEffect ( () => {

        if (isFocused) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isFocused])

    useEffect( () => {
        setValueWithCommas(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    },[value])

    useEffect( () => {
        if (props.value % 10 !== 0) {
            setStep(1)
        } else {
            setStep(10)
        }
        setBarPos(() => props && !!props.value ? props.value : 0)
        setDomain( () => props && !!props.domain ? [0,Math.floor((props.domain[1]/10) *10)] : [0,10])
        setValue(() => props && !!props.value ? props.value : 0)
        

    }, [props])

    useEffect( () => {

        console.log(props)
        
        if (props.value % 10 !== 0) {
            setStep(1)
        } else {
            setStep(10)
        }
        setBarPos(() => props && !!props.value ? props.value : 0)
        setDomain( () => props && !!props.domain ? [0,Math.floor((props.domain[1]/10) *10)] : [0,10])
        setValue(() => props && !!props.value ? props.value : 0)
        
        if(props.isFocused) {
            setFocused(true)
        }   

    }, [])

    const roundTo = 10
    
    return (
        <>
        {isFocused && <StyledFieldWrapper isFocused>{props.icon && props.icon}<StyledField 
                        tabIndex={props.tabIndex}
                        value={value} 
                        isFocused
                        onBlur={() => setFocused(false)}
                        onChange={ e => changeValue(e.target.value) }
                        onKeyDown={props.onKeyDown}
                        inputMode={"numeric"}
                        ref={inputRef} /></StyledFieldWrapper>}
        {!isFocused && <StyledFieldWrapper
                            onClick={() => setFocused(true)}>{props.icon && props.icon}<StyledField 
                        style={props.ordered ? {}: {color: "red"}}
                        tabIndex={props.tabIndex}
                        value={valueWithCommas}
                        onFocus={() => setFocused(true) }                        
                        onChange={ e => changeValue(e.target.value) } />{props.children && props.children}</StyledFieldWrapper>}
            
        {!!props.showSlider && <div style={{ margin: "15px auto 0px auto", height: 20, width: "70%" }}>
            <Slider domain={domain} values={[barPos]} mode={2} step={step} 
            rootStyle={{position: "relative", width: "100%"}}
            onUpdate={ (e) => { changeValueSlider(e[0]);} }
            onChange={ (e) => { setPropValue(e[0]);}}>
            
            <Rail>
                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                    {handles.map(handle => (
                    <Handle
                        key={handle.id}
                        handle={handle}
                        domain={[props.domain]}
                        getHandleProps={getHandleProps}
                    />
                    ))}
                </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                    <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                    />
                    ))}
                </div>
                )}
            </Tracks>
            </Slider>
        </div>}
        </>
    )

}

Cf.propTypes = {
    domain: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired
    /*change: PropTypes.func.isRequired*/
}

export default Cf;