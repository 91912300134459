import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BoardInput = styled.input`
    width: 65%;
    margin: 5px auto;
    display: block;
    border-radius: 5px;
    resize: none;
    background-color: rgba(255,255,255,0.8);
    font-size: 1.2em;
    letter-spacing: 2px;

    @media (max-width: 768px) {
        font-size: 3.5vw;
    }
`;

const BoardField = (props) => {

    return (
        <BoardInput value={props.value} onFocus={props.onFocus} onChange={ (e) =>  props.onChange(e)} />
    )
}

BoardField.propTypes = {
    onChange: PropTypes.func.isRequired
}

export default BoardField;
