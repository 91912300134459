import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';

const ResultTextArea = styled.textarea`
    width: 100%;
    height: 100%;
    max-height: 250px;
    font-size: 0.6em;
    font-family: 'Roboto', sans-serif;
    color: ${props => props.theme.textColorReverse};
    white-space: pre-line;
    wrap: pre;
    resize: none;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0;
    padding-top: 2px;
    background-color: inherit;
    margin-bottom: 5px;

    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(173, 173, 137, 0.6);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(173, 173, 137, 0.6);
        border-radius: 4px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: grey;
    }
    `;

const TextAreaWrapper = styled.div`
    width: 90%;
    padding: 5px;
    margin: 0 auto;
    margin-bottom: 5px;
    margin-top: 2px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: white;

    button {
        background-color: inherit;
        color: ${props => props.theme.textColorReverse};
        border: 0;
        outline: none;
        min-width: 20px;
        align-self: flex-start;

    }
    `;



const RTW = (props) => {

    const checkMark = <span>&#10003;</span>

    const [copyText, setCopyText] = useState('C')

    // ref to textarea for copy
    const textAreaRef = useRef()

    const copyResultToClipboard = (e) => {
        
        console.log(textAreaRef)
        if ( !!props.resultText && props.resultText.length > 10) {
            textAreaRef.current.select()
            document.execCommand("copy")
            //navigator.clipboard.write([props.resultText])
            setCopyText(checkMark)
        }
        
    }

    // received new props
    useEffect(() => {

        setCopyText('Copy')

    }, [props])

    return (
        <TextAreaWrapper>

            <ResultTextArea 
                value={props.resultText} 
                onClick={ (e) => copyResultToClipboard(e)}
                ref={textAreaRef} readOnly />
            {!navigator.userAgent.match(/ipad|iphone/i) && <button onClick={ () => copyResultToClipboard(textAreaRef)}>
                {props && props.resultText && copyText}                
            </button>}
        </TextAreaWrapper>
        )
}

export default RTW;