import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import SettingsRow from './components/SettingsRow';
import TourneyChop from 'tourney-chop'
import ChopFieldWithSlider from './components/ChopFieldWithSlider'
import ChopToggleGroup from './components/ChopToggleGroup'
import LockButton from './components/LockButton'
import PStyled from './styled-components/PStyled'
import ChopResult from './components/ChopResult'
import AddPlayerButton from './components/AddPlayerButton'
import ToolTips from './tooltip/tooltip'
import IcmLauncher from './components/IcmLauncher'
import ResultTextWrapper from './components/ResultTextWrapper'
import ClearButton from './components/ClearButton'
import ErrorChopBox from './components/ErrorChopBox'
import {StyledDeletePlayerButton} from './styled-components/ChopStyledField'
import IcmWorker from './worker/worker'
import TestWorker from './worker/TestWorker'
import WorkerBuilder from './worker/worker-builder';
import PrizeButton from './components/PrizeButton'
import ChipsIcon from './images/chips.svg'

const keyFrameFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const AnalyzerDiv = styled.div`
    background: ${props => props.theme.background};
    height: auto;
    width: 100%;
    padding: 0px;
    border-radius: 0 0 5px 5px;
    animation: ${keyFrameFadeIn} 1s ease-in-out;    
    border-top: 1px solid ${props => props.theme.inputBackground};
    margin-bottom: 20px;
    min-height: 100vh;
`;

const FlexDiv = styled.div`
    display: flex;
    `;
const SplitDiv = styled.div`
    flex: 1 1 auto;
    margin: 2px;
    `;
const ColumnDiv = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    `;



function Ca(props) {

    const [worker, setWorker] = useState(new WorkerBuilder(IcmWorker))

    const buildWorker = () => {

        console.log("building worker")
        worker.terminate()
        const w = new WorkerBuilder(IcmWorker)
        // Worker
        w.onmessage = (message) => {
            if (message) {
                setChopResults(message.data)
                setICMisRunning(false)
                setShowICMLauncher(false)
            }
        }
        console.log("setting worker")
        setWorker(w)
    }

    //window.localStorage.setItem('chopInputs', JSON.stringify( [30000,30000,3,[20000,5000,5000],[20000,5000,5000]]) )
    // This really won't be enough. We want to store all tourneychop data in localstorage
    //const chopInputs = window.localStorage.hasOwnProperty('chopInputs') ? 
    //    JSON.parse(window.localStorage.getItem('chopInputs'))
    //    : [100000,30000,4]

    // Main Tourney Chop class, handles all input data and chop results
    const [tc, setTc] = useState({})

        /**
     * Runs tourneychop's icm calculator in a worker wrapper
     * @returns 
     */
    const runICM = async () => {
        worker.postMessage({chips: chips, payouts: payouts})
    }

    const [showICMLauncher, setShowICMLauncher] = useState(false)
    const [ICMisRunning, setICMisRunning] = useState(false)
    /**
     * To be used instead of calling touney chop directly.
     * ICM chop can be expensive if done with a large number of players
     */
    const calcICM = (auto = true) => {
        if (auto) {
            // calcICM was run automatically
            if(tc.chipsAndPrize[0].length > 5) {
                // with over 4 players, we should only run ICM at user request

                if (!showICMLauncher) {
                    setShowICMLauncher(true)
                }

                return []

            } else {

                // if we have 4 players or less, it's fast
                if (showICMLauncher) setShowICMLauncher(false)
                return tc.chopICM()
            }
        } else {
            // calcICM was initiated by user
            setICMisRunning(true)
            
        }
    }

    // Layout state
    const [chopType, setChopType] = useState(true)  // true = ICM (first)  tc.chopICM or tc.chopChips
    const [resultText, setResultText] = useState("")
    const [showSliders, setShowSliders] = useState(false)

    // Set in tc useEffect
    const [payouts, setPayouts] = useState([])  // [] array of payout structure tc.chipsAndPrize[1]
    const [chips, setChips] = useState([])     // [] array of players' chip count tc.chipsAndPrize[0]
    const [totals, setTotals] = useState([0,0]) // [ chips in play, prize pool] tc.totals
    const [locks, setLocks] = useState([0,0])      // [ true, false ] (chip total, prize pool)
    const [playersLock, setPlayersLock] = useState(false) // if locked, can only set players through settings
    const [chopResults, setChopResults] = useState( [] )

    // hacky way of pre focusing new chips element
    // If the index of the element is in the array, it will be passed an inFocus attribute initially
    // Note, could cause a rerender if placed here after the element is rendered 
    const [chipsPreFocus, setChipsPreFocus] = useState([])
    const [prizePreFocus, setPrizePreFocus] = useState([])


    // Debug
    const [developerMessage, setDeveloperMessage] = useState("")


    /**
     * Converts the TourneyChop object into applicable state.
     * 
     * Can be ran every time a variable is updated (or less than for performance reasons)
     * 
     * Optional type argument can specify which kind of state update if not everything should
     * be updated.
     */
    const updateState = (type = 'all') => {

        setChips([...tc.chipsAndPrize[0]])

    }

    const updatePlayerChips = (c, pos) => {

        //console.log("Updating: ", pos, !isNaN("d"), "value:", c, tc.chipsAndPrize)
        
        if (!isNaN(c) && c !== "") {
            try {
                tc.setChipCount(c,pos)
                setChips([...tc.chipsAndPrize[0]])
                setTotals(tc.totals)       
                //setChopResults(() => chopType ? calcICM() : tc.chopChips())
            } catch (e) {
                props.appErrorHandler({"ChopAnalyzer": e})
            }
        }
    }

    const updatePrizes = (c, pos) => {

        //console.log("updatePrizes", c, pos)
        try {
            tc.setPayout(c,pos)
            setPayouts(tc.chipsAndPrize[1])
            setTotals(tc.totals)
            //setChopResults(() => chopType ? calcICM() : tc.chopChips())
        } catch (e) {
            props.appErrorHandler({"ChopAnalyzer": e})
        }
    }

    const updateLocks = (pos) => {

        if ( pos === 0 ) {
            tc.locked = !tc.locked

            /*
             * if locked is set while chip total doesn't equal the sum of chips,
             * the player is probably switching to a locked version and reseting
             * the chip counts is neccessary 
             */
            if (tc.locked && tc.totals[0] !== chips.reduce( (a,b) => a+b) ) {
                tc.resetChipCounts()
                setChips([...tc.chipsAndPrize[0]])
                setTotals(tc.totals)
            }

            // modify sliders, we don't want sliders used on unlocked
            if (tc.locked) {
                setShowSliders(true)
            } else {
                setShowSliders(false)
            }
        } else if ( pos === 1) {
            tc.plocked = !tc.plocked
        } else {
            throw new Error("Received Bad Locked Position: ", pos)
        }

        setLocks( locks => {
            let l = locks.slice()
            l[pos] = !l[pos]; 
            return l;
        })
        
    }

    const updateTotals = (pos, value) => {


        try {
        if ( pos === 0) {
            tc.setChipTotal(value)
        } else if ( pos === 1) {
            tc.setPrizePool(value)
        } else {
            throw new Error("Received bad position: ", pos)
        }

        //console.log(tc.totals, ...tc.chipsAndPrize[0], ...tc.chipsAndPrize[1])
        //console.log(payouts)
        setTotals(tc.totals)
        setChips([...tc.chipsAndPrize[0]])        
        //setPayouts([...tc.chipsAndPrize[1]])
        } catch (e) {
            props.appErrorHandler({"ChopAnalyzer": e})
        }

    }

    /**
     * Finds the proper domain for a player's chips.
     * Max should not allow setting more than available from above while leaving
     * 1 for those below
     * @param {} index 
     */
    const findProperDomain = (index, locked, isChips = true) => {

        const MAXDOMAIN = 100000000
        
        // if locked, use staggered basis
        if (locked) {
            let maxDomain = tc.totals[0]
            if (isChips) {
                maxDomain = tc.totals[0];
                for ( let i = 0; i < tc.players; i ++) {
                    if ( i < index ) {
                        maxDomain -= tc.chipsAndPrize[0][i]
                    } else if ( i > index ) {
                        maxDomain -= 1;
                    }
                }
            } else {
                maxDomain = tc.totals[1]
            }

            return [0,maxDomain]
        } else {
            // if unlocked, let 1st position be open and following positions set according
            // to 1st
            if (index === 0) {
                return [0,MAXDOMAIN]
            } else {
                return isChips ? [0, chips[0]] : [0,payouts[0]]
            }

        }
    }

    /**
     * Checks that index is less than previus and greater than next.
     * Returns bool, false if out of order
     * @param {int} index 
     * @param {Array} array 
     */
    const checkOrder = (index, array) => {
        if (index < 0 || index >= array.length) {
            return false;
        }

        if (index != 0 && array[index] > array[index-1]) {
            return false;
        }

        if (index != array.length -1 && array[index] < array[index+1] ) {
            return false
        }

        return true

    }

    const newPlayerAmount = (num) => {
        let diff = chips.length - num
        let initialPlayerLength = chips.length
        if (diff > 0) {
            for (let i = 0; i < diff; i++) {
                deletePlayer(initialPlayerLength - 1 - i)
            }
        } else if (diff < 0) {
            for (let i = 0; i > diff; i--) {
                addPlayer()
            }
        }
    }

    const addPlayer = () => {
        try {
        tc.addPlayer()
        setPayouts(tc.chipsAndPrize[1])
        setChips([...tc.chipsAndPrize[0]])
        setTotals(tc.totals)
        //setChopResults(() => chopType ? calcICM() : tc.chopChips())
        } catch (e) {
            props.appErrorHandler({"ChopAnalyzer": e})
        }
    }

    const deletePlayer = (index) => {

        // set chop to 0
        updatePlayerChips(0,index)

        // remove from prefocus if exists
        setChipsPreFocus( prev => prev.filter( v => v !== index))
        setPrizePreFocus( prev => prev.filter( v => v !== index))

    }

    const createResultText = () => {
        let resultText = "";
        let pl = ['st', 'nd', 'rd', 'th']
        resultText += chopResults.reduce ( (a, r, i) => {
            let place = findPlace(r,chopResults)
            let placeText = place + 1 + (place > 3 ? pl[3] : pl[place])
            return a + placeText + ": " + r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "\r\n"
        }, "")
        return resultText
    }

    /**
     * Finds the order of value in chopResult.
     * For determining the position of the chop rather than arrangment
     * in seat order
     * @param {*} value 
     * @param {*} chopResult 
     */
    const findPlace = ( value, chopResult ) => {
         return [...chopResult].sort((a,b) => b-a).findIndex(e => e === value)
    }



    /**
     * To be used by a clear button
     */
    const clearChipsInPlay = () => {
        window.localStorage.removeItem("chop")
        let newTc = new TourneyChop(1000000, 65000,1,[1000000],[65000],locks[0])
        setTc( newTc )
        setPayouts(newTc.chipsAndPrize[1])
        setChips(newTc.chipsAndPrize[0])
        setTotals(newTc.totals)
        setPrizePreFocus([])


    }

    const handleKeyDown = event => {
        if (event.key.toLowerCase() == 'enter') {
            let form = event.target.form;
            let index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    /**
     * Initial load just looks for stored values
     */
    useEffect ( () => {

        buildWorker()

        let chopInputs = window.localStorage.hasOwnProperty('chopInputs') ? 
            JSON.parse(window.localStorage.getItem('chopInputs'))
            : [100000,30000,4]

            console.log("chopInputs: ", chopInputs)
            console.log(...chopInputs)
        setTc(new TourneyChop(...chopInputs))

    }, [])

    /**
     * Typically will only run when opened
     */
    useEffect ( () => {

        if ("chipsAndPrize" in tc) {
            setPayouts(tc.chipsAndPrize[1])
            setChips(tc.chipsAndPrize[0])
            setLocks([tc.locked, tc.plocked])
            setTotals(tc.totals)
            //setChopResults( () => chopType ? calcICM() : tc.chopChips() )
        }

    },[tc])

    /**
     * Update chop values when any value changed
     */
    useEffect ( () => {

        if(ICMisRunning) {
            buildWorker()
            setICMisRunning(false)
        }

        if ("chipsAndPrize" in tc) {
            try {
                //console.log("chopping", tc.prizePool)
                chopType && setChopResults(calcICM())
                !chopType && setChopResults(tc.chopChips())
                window.localStorage.setItem('chopInputs',JSON.stringify(tc.dataSave))
            } catch (e) {
                props.appErrorHandler({"ChopAnalyzer": e})
            }
        }

    }, [chopType, totals, locks])

    useEffect ( () => {

        if (ICMisRunning) {
            console.log("TODO: worker async running icm")
            runICM()
        }

    }, [ICMisRunning])

    useEffect ( () => {
        setResultText(createResultText())
    },[chopResults])

    useEffect ( () => {
        
        // is an add if has index out of bounds
        if (chipsPreFocus.length > 0 && chipsPreFocus.includes(chips.length)) {
            addPlayer()
        }
    },[chipsPreFocus])

    useEffect ( () => {
        if (prizePreFocus.length > 0 && prizePreFocus.includes(payouts.length)) {
            addPlayer()
        }
    }, [prizePreFocus])

    /*
    const chipsColumn = chips.map( (e,i) => 
        <ChopFieldWithSlider key={i} value={e} update={ e => updatePlayerChips(e,i) } />
    )*/


    //console.log(tc.chipsAndPrize)
    
    return (
        <AnalyzerDiv>
            <form onSubmit={(e) => {e.preventDefault();}}>
            <ToolTips tipName={"chop"}>
            
            <SettingsRow 
                players={chips.length}
                playersLock={playersLock}
                playersLockToggle={() => setPlayersLock(!playersLock)}
                setPlayers={ (newPlayers) => newPlayerAmount(newPlayers)}
                sliderToggle={ () => setShowSliders(!showSliders)}
                showSlider={showSliders} />

            <ChopToggleGroup value={chopType} update={ (t) => {
                setICMisRunning(false)
                setChopType( t )
            } }/>

            {/* Totals Group*/}
            <FlexDiv>
                <SplitDiv>

                    <ColumnDiv>
                        <PStyled>Chips in Play</PStyled>
                        <LockButton lockValue={locks[0]}
                                    tabIndex={1}
                                    showLock
                                    onKeyDown={handleKeyDown}
                                    toggleLock={ () => updateLocks(0) }
                                    value={totals[0]}
                                    type="button"
                                    update={ (value) => updateTotals(0,value) } />
                    </ColumnDiv>

                </SplitDiv>
                <SplitDiv>

                    <ColumnDiv>
                    </ColumnDiv>

                </SplitDiv>
                <SplitDiv>

                    <ColumnDiv>
                        <PStyled>Remaining Prize Pool</PStyled>
                        <LockButton lockValue={true}
                                    tabIndex={24}
                                    toggleLock={ () => updateLocks(1) }
                                    value={totals[1]}
                                    remainingPrizePool={payouts.reduce( (a,b) => a+b,0)}
                                    type="button"
                                    update={ (value) => updateTotals(1,value) } />
                    </ColumnDiv>

                </SplitDiv>
                
            </FlexDiv>

            {/*Players Group*/}
            <FlexDiv>
                <SplitDiv>

                    <ColumnDiv>
                        {chips.map( (e,i) => 
                        <ChopFieldWithSlider key={i}
                            icon={<img src={ChipsIcon} style={{width: "20px"}}/>}                        
                            isFocused={chipsPreFocus.includes(i)}
                            tabIndex={i+2}
                            showSlider={showSliders}
                            value={e}
                            ordered={checkOrder(i,chips)}
                            update={ ev => updatePlayerChips(ev,i) }
                            onKeyDown={handleKeyDown}
                            domain={ findProperDomain(i,locks[0]) }>
                                {!playersLock && i !== 0 && (i === chips.length - 1) ? 
                                <StyledDeletePlayerButton 
                                    onClick={ () => deletePlayer(i) }/>
                                :null}
                                    
                            </ChopFieldWithSlider>
                        
                        )}
                    </ColumnDiv>
                    {!playersLock && <input style={{height: "0px", width: "0px", margin: "0", padding: "0", backgroundColor: "transparent", outline: "none", border: "none"}}
                     tabIndex={chips.length + 2} onFocus={() => {
                         setDeveloperMessage("you focused me" + [chips.length, ...chipsPreFocus]);
                         
                         setChipsPreFocus( prev => [chips.length,...prev])
                         //addPlayer()
                     }} />}

                </SplitDiv>
                <SplitDiv>

                    { chips.length > 0 && chips.reduce( (a, b) => a+b) === totals[0] ? 
                    <ColumnDiv>
                        {showICMLauncher && chopType && 
                            <IcmLauncher 
                                running={ICMisRunning}
                                calcICM={() => {
                                    calcICM(false)}
                                }/>}
                        {chopResults.map ( (e,i) => 
                            <ChopResult showSlider={showSliders} key={i} value={e} place={findPlace(e, chopResults)} />
                        )}
                    </ColumnDiv>

                    : <ErrorChopBox reset={ () => updateTotals(0, chips.reduce( (a,b) => a+b))} />

                    }

                </SplitDiv>
                <SplitDiv>

                    <ColumnDiv>
                        {payouts.map ( (e,i) => 
                        <ChopFieldWithSlider key={i}
                            isFocused={prizePreFocus.includes(i)}
                            tabIndex={i+25}
                            showSlider={showSliders}
                            onKeyDown={handleKeyDown}
                            ordered={checkOrder(i,payouts)}
                            value={e}
                            update={ e => updatePrizes(e,i)}
                            domain={ findProperDomain(i, locks[1], false) }>
                                {!playersLock && i !== 0 && (i === chips.length - 1) ? 
                                <StyledDeletePlayerButton 
                                    onClick={ () => deletePlayer(i) }/>
                                :null}
                        </ChopFieldWithSlider>
                        )}
                    </ColumnDiv>
                    {!playersLock && <input style={{height: "0px", width: "0px", margin: "0", padding: "0", backgroundColor: "transparent", outline: "none", border: "none"}}
                     tabIndex={chips.length + 25} onFocus={() => {
                         setDeveloperMessage("you focused me");
                         console.log("you focused me" + [chips.length, ...prizePreFocus])
                         setPrizePreFocus( prev => [chips.length,...prev])
                         
                     }} />}

                </SplitDiv>
                
            </FlexDiv>

            <div style={{display: 'flex'}}>
                <div style={{display: 'flex', flexGrow: '2'}}>
                    <AddPlayerButton 
                        type="button" 
                        playersLock={playersLock}
                        onclick={() => {
                            if (!playersLock) {
                                addPlayer()
                            } else {
                                setPlayersLock(false)
                            }
                        }} />
                </div>
                <div style={{display: 'flex', flexGrow: '1'}}>
                    <ClearButton onClick={() => clearChipsInPlay() } />
                </div>
            </div>
            <ResultTextWrapper resultText={resultText} />
        </ToolTips>
        </form>
        <div>{ process.env.NODE_ENV === "development" ? developerMessage : ""}</div>
        </AnalyzerDiv>
    );
}

export default Ca;