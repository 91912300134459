import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TinyProfile from './components/TinyProfile';
import PStyled from './styled-components/PStyled';
import GithubIcon from './images/github.svg';
import FacebookIcon from './images/facebook.svg';
import LinkedinIcon from './images/linkedin.svg';

const ProfileDiv = styled.div`
  width: 100%;
  display: flex;
  `;

const SplitDiv = styled.div`
  flex: ${props => props.flex ? `${props.flex}` : `1`};
`;

const SocialButton = styled.button`
  height: 20px;
  width: 130px;
  border-radius: 6px;
  margin: 1px 15px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;

  p {      
      margin: 0;
      padding: 0;
      font-size: .9em;
  }
  `;


const Up = (props) => {


    return (
        <ProfileDiv>
        {/** added false until login is fixed */}
        {(false && !!props.user && !!props.user.name && props.user.id !== 0) ? 
            <>
            <SplitDiv flex={"2"}>
                <TinyProfile user={{...props.user}} appErrorHandler={(e) => props.appErrorHandler(e)} />
            </SplitDiv>
            {/*<SplitDiv flex={"1"}> Hands</SplitDiv>
            <SplitDiv flex={"1"}> Account</SplitDiv>*/}

            </> :
            <>
                <SplitDiv flex={"2"} style={{paddingLeft: "5px"}}>
                    <PStyled>
                        Idc Beta
                        {/* <span> <a href="https://idontchop.com/portfolio-war/oauth2/authorization/wordpress">Login</a> </span> */}
                    </PStyled>
                </SplitDiv>

                {/*<SplitDiv flex={"3"} >
                    
                <a href={"https://idontchop.com/portfolio-war/oauth2/authorization/facebook"}>
                    <SocialButton>
                        <img src={FacebookIcon} alt="Facebook Logo" style={{ height: "15px", width: "auto"}} />
                        
                    </SocialButton>
                </a>
                </SplitDiv>                
                <SplitDiv>
                    <a href={"https://idontchop.com/portfolio-war/oauth2/authorization/github"}>
                    <SocialButton>
                        <img src={GithubIcon} alt="Facebook Logo" style={{ height: "15px", width: "auto"}} />
                        
                    </SocialButton>
                    </a>
                </SplitDiv> */}
                
                <SplitDiv flex={"2"}>
                    <PStyled>
                        <span> <a href="https://idontchop.com">I Don't Chop .com</a> </span>
                    </PStyled>
                </SplitDiv>
            </>
        
        }
        </ProfileDiv>
    )
}

export default Up;