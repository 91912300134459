
export const standard = {
    inputBackground: "#FFFFFC",
    inputBackgroundUnselected: "#FFFFFC",
    inputBackgroundSelected: "#fafaed",
    inputBorder: "#5E3D22",
    inputBorderUnselected: "#5E3D22",
    inputBorderSelected: '#ab9952',
    inputHighlight: '#a7c6e8',
    inputHighlightText: '#4a4a4a',
    checkboxSelected: "#707070",
    checkboxUnselected: 'white',
    launchButton: '#c4c0a3',
    launchButtonOn: `#85C43D`,
    toggleButtonBackground: "#FFFFFC",
    toggleButtonOnBackground: "#85C43D",
    toggleButtonTextSelected: "#707070",
    resultBackground: "#f7fffa",
    clearButtonBackground: "#ff9494",
    background: "rgb(94,61,34);background: linear-gradient(0deg, rgba(94,61,34,0.17690826330532217) 0%, rgba(141,118,99,0.06766456582633051) 15%, rgba(201,198,198,0.45702030812324934) 51%, rgba(150,128,110,0.0760679271708683) 83%, rgba(94,61,34,0.09567577030812324) 100%)",
    textColor: "#707070",
    h1TextColor: "#707070",
    textColorReverse: "#707070",
    svgFill: "#707070",
    fontStyle: "'Roboto', sans-serif",
    fancyFontStyle: `"Playfair Display", "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif`
}

export const night = {
    ...standard,
    inputBackground: "black",
    inputBackgroundUnselected: "#1a1a1a",
    inputBackgroundSelected: "#1f1f1f",
    inputBorder: "#5E3D22",
    inputBorderUnselected: "#5E3D22",
    inputBorderSelected: '#ab9952',
    inputHighlight: '#a7c6e8',
    inputHighlightText: '#4a4a4a',
    checkboxSelected: "#707070",
    checkboxUnselected: '#d4d4d4',
    launchButton: '#c4c0a3',
    launchButtonOn: `#85C43D`,
    toggleButtonBackground: 'black',
    toggleButtonOnBackground: "rgba(51,145,70,0.5)",
    toggleButtonTextSelected: "#ededed",
    resultBackground: "#5e5e5e",
    clearButtonBackground: /*"#ff9494"*/ "rgba(255, 181, 181,0.9)",
    textColor: "#d9d5c5",
    h1TextColor: "#e3dada",
    background: "#222",
    textColorReverse: "#707070"
}
