import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HandFieldWrapperDiv = styled.div`
    ${props => props.selected ? `border: 2px #5E3D22 solid`: `border: 1px #5E3D22 solid`};
    padding: 4px;
    margin: 5px 0px;
    background-color: ${props => props.selected ? `#fcfcca`: `#FFFFFC`};
    position: relative;
    display: inline-block;

    div {
        display: flex;
        
    }
`;
/**
 * Wrapper for an individual hand. This component doesn't know what hand it is wrapping.
 * 
 * @param props 
 */
function HandFieldWrapper (props) {

    //process.env.NODE_ENV === 'development' && console.log(props)
    return (
        <HandFieldWrapperDiv {...props} onClick={() => props.onClick()}>
            {!!props.children && props.children}
            
        </HandFieldWrapperDiv>
    )

}

export default HandFieldWrapper;

HandFieldWrapper.propTypes = {

}