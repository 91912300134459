import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const StyledResultDiv = styled.div`
    border-radius: 5px;
    background-color: none;
    padding: 0.5em 0;
    margin: 10px auto 40px auto;

    color: ${props => props.theme.textColor};

    text-align: center;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);
    height: 80%;
    max-width: 140px;
    padding: 5px;

    @media (max-width: 768px) {
        margin: 10px auto 24px auto;
    }

    @media (max-width: 411px) {
        max-width: 120px;
    }
  `;

const StyledH3 = styled.h3`
    padding: 0;
    margin: 0;
    text-align: center;
    display: inline-block;
    font-size: 0.8em;

    @media (max-width: 414px) {
        font-size: 0.6em;
    }
`;

const StyledP = styled.p`
    margin: 0.5em auto;
    width: 150px;
    padding: 0;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    text-align: center;
`;

const LaunchButton = styled.div`
    background-color: ${props => props.on ? props.theme.launchButtonOn : props.theme.launchButton};
    display: block;
    width: 100%;
    height: 50%;
    padding: 5px 1px;
    white-space: normal;
    border-radius: 15px;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.6); 
    text-shadow: 1px 1px 4px 1px rgba(0,0,0,0.6);
    box-shadow: 6px 3px 9px rgba(0,0,0,.7);
    color: #${props => props.theme.textColor};
    text-align: center;

    p {
    }

    span {
        display: block;
        color: #ff3838;
        font-size: 0.8em;
    }
`

const ErrorChopBox = (props) => {

    return (            
        <StyledResultDiv>
        <p></p>
        {props.running && <LaunchButton
                on
                disabled>Working...</LaunchButton> }
        {!props.running && 
            <LaunchButton
                onClick={() => props.reset()}><p>Chip Total Reset<span>Total set while unlocked?</span></p></LaunchButton>}
        </StyledResultDiv>

    );

}

export default ErrorChopBox

ErrorChopBox.propTypes = {
    running: PropTypes.bool.isRequired,
}