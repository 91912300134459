import React, { createContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const StyledP = styled.p`
    border: 0;
    margin: 0;
    padding: 0;
    background-color: inherit;
    color: ${props => props.theme.textColor};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 600;
    line-height: 24px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);
    overflow: hidden;
    word-wrap: none;
    white-space: nowrap;

    @media (max-width: 450px) {
        font-size: 0.9em;
    }
`;

export default StyledP;