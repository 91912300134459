import React, { useState, useEffect } from 'react';
import UserProfile from './UserProfile'
import HandAnalyzer from './HandAnalyzer'
import ChopAnalyzer from './ChopAnalyzer'
import Products from './Products'
import ErrorModal from './components/ErrorModal'
import idcApi from './lib/idcApi';
import Collapsed from './components/Collapsed';
import styled, { ThemeProvider } from "styled-components";
import { standard, night } from "./themes/themes.js";
import ThemeToggle from './components/ThemeToggle';
import './App.css'
import ToolTips from './tooltip/tooltip';

const guestUser = {
  "id": 0,
  "name": "Guest",
  "email": "Guest@idontchop.com",
  "company": "IDC",
  "url": "https://idontchop.com",
  "created": "2020-01-16T17:16:03.000+00:00",
}

const PageWrapper = styled.div`
  background-color: ${props => props.theme.background};
  min-height: 100vh;
  position: relative;
`


function App() {

  /**
   * Will be used with a modal
   */
  const [errorState, setErrorState] = useState(false)

  const [user, setUser] = useState({});

  const [useStandardTheme, setStandardTheme] = useState(true)

  /**
   * Component did mount
   */
  useEffect ( () => {

    

    // For development
    //idcApi.setToken("eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzMyIsImV4cCI6MTYyMzgwNTU0MH0.sw6Pa_aExaSTDwHTHrXFIJBQUfep09f5v277zHeW31brkLofQOGZR4owR_YhK7QUULZw1paxPNCWHS7EWodwQA")
    
    let params = new URLSearchParams(window.location.search);

    if(params.has("t")) {
      // set token and redirect
      idcApi.setToken(params.get("t"))
      let url = window.location.href
      window.location.replace(url.split('?')[0] )
    }

    // token in url always higher priority

    // Load User Profile (dev token set)
    if (window.localStorage.getItem("token") === null && !params.has("t")) {
      setUser(guestUser)
    } else {
      idcApi.getJson('profile')
        .then( (response) => {
          setUser(response)
        })
        .catch ( e => {
          setUser(guestUser)
        });
    }


  }, [])

  useEffect( () => {
    // fire and forget
    idcApi.postJson('hit', {name: 'idc app', message: navigator.userAgent})
      .catch ( e => {console.log("Error saving hit.")})

  },[])

  useEffect ( () => {

    if (!useStandardTheme) {
      document.body.style.backgroundColor = "#222"
    } else {
      document.body.style.backgroundColor = "white"
    }

  },[useStandardTheme])

  /**
   * Passed down to main components to be called when an error occurs.
   * Uses errorState state to display modal
   */
  const appErrorHandler = (e) => {    
    setErrorState({error: e})
  }

  return (
    <div className="App" style={{maxWidth: "1024px", width: "100%", display: "block", margin: "0 auto"}}>
      <ThemeProvider theme={useStandardTheme ? standard : night}>
        <ToolTips tipName={"main"}>
          <PageWrapper>  
            <ThemeToggle onClick={ () => setStandardTheme(!useStandardTheme)} useStandardTheme={useStandardTheme} />
            <UserProfile  user={user} appErrorHandler={(e) => appErrorHandler(e)}/>

            <Collapsed title={"Tournament Chop Analyzer"}>
              <ChopAnalyzer appErrorHandler={(e) => appErrorHandler(e)}/>
            </Collapsed>
            
            <Collapsed title={"Hand Range Analyzer"}>
              <HandAnalyzer appErrorHandler={(e) => appErrorHandler(e)}/>
            </Collapsed>

            <Collapsed title={"Idc Products"}>
              <Products appErrorHandler={(e) => appErrorHandler(e)}/>
            </Collapsed>
            
            {/*<img src={testImage} />*/}
            {!!errorState && <ErrorModal {...errorState} clearError={() => setErrorState(false) }/>}
          </PageWrapper>
        </ToolTips>
      </ThemeProvider>
    </div>
  );
}

export default App;

/*
<header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      */
