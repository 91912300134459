import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'

export default function one (e) {

    const dems = {
        height: "300px"
    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>Entering Totals:</StyledH2>
        <StyledP>After entering totals, you can lock or unlock the totals as you enter the individual chip counts or prizes. 
            Leaving the chip count set to locked may assist distributing the chips.</StyledP>
        <StyledP>
            If the tournament has already paid players, it will be easier to enter prizes with the pool unlocked.
        </StyledP>

        {e}
    </StyledModal>
    
    </>

}