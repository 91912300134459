import styled from 'styled-components'

export const StyledDiv = styled.div`

    max-width: 220px;
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 0.7em 0.5em;
    margin: 2px auto;
    flex-wrap: wrap;
    position: relative;
    
    & ::selection {
        background: ${props => props.theme.inputHighlight};
        color: ${props => props.theme.inputHighlightText};
    }

    & ::-moz-selection {
        background: ${props => props.theme.inputHighlight};
        color: ${props => props.theme.inputHighlightText};
    }

    border: 2px solid ${props => props.isFocused ? props.theme.inputBorderSelected : props.theme.inputBorderUnselected};
    border-radius: 5px;
    background-color: ${props => props.isFocused ? props.theme.inputBackgroundSelected : props.theme.inputBackgroundUnSelected};
    padding: 0;
    height: auto;

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 0.7em 0.2em;
        margin: 2px auto;
        width: 180px;
        height: auto;
    }

    @media (max-width: 414px) {
        font-size: 0.8em;
        padding: 0.7em 0.2em;
        margin: 2px auto;
        width: 160px;
        height: auto;
    }

    `;

export const FloatingButton = styled.img`
    margin: auto 5px auto 0;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    height: 40px;
    width: 40px;
    z-index: 5;
    display: flex;

    @media (max-width: 768px) {
        height: 35px;
        width: 35px;      

    }

    @media (max-width: 414px) {
        height: 35px;
        width: 35px;
    }
    `;

export const StyledTotalField = styled.input`

    background-color: inherit;
    padding: 0.7em 0.5em;
    margin: 2px 2px 2px 0;
    outline: none;
    border: 0;
    height: auto;

    flex-grow: 2;
    max-width: 150px;

    color: ${props => props.theme.textColor};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.4em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 0px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        font-size: 1.3em;
        padding: 0.7em 0.2em;
        margin: 2px auto;
        width: 90px;
        height: auto;
    }

    `;

export const StyledSmallTotalField = styled.input`

    background-color: inherit;
    padding: 0.7em 0.5em;
    outline: none;
    border: 0;

    flex-grow: 1;
    max-width: 150px;
    width: 90px;

    color: ${props => props.theme.textColor};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.7em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 0px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

    @media (max-width: 768px) {
        font-size: 0.7em;
        margin: 2px auto;
        width: 90px;
    }

    `;
