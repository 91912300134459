import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HandTextFieldDiv = styled.div`
    padding: 0;
    width: 96%;
    display: inline-block;
    margin-top: 5px;

    @media (max-width: 768px) {

        width: 100%;

    }
    
    @media (max-width: 414px) {

        width: 100%;

    }
`;

const HandTextInput = styled.textarea`
    width: 98%;
    display: block;
    border-radius: 5px;
    resize: none;
    background-color: inherit;
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;
    color: #707070;
    border: 0;

    @media (max-width: 768px) {

        font-size: 3vw;

    }

    &:focus {
        outline: none;
    }

    &::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(173, 173, 137, 0.6);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(173, 173, 137, 0.6);
        border-radius: 4px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: grey;
    }
    `;

 

const HandField = (props) => {

    const [isModified, setModified] = useState(false)
    const [value, setValue] = useState(props.hand || "")

    const onTypedChange = (value) => {

    }

    React.useEffect( () => {
        setValue(props.hand || "")
    },[props])

    return (
        <HandTextFieldDiv>
            <HandTextInput value={value}
                inputMode={ props.toggleKeyboard ? "text" : "none" }
                onFocus={props.onFocus}
                onBlur={ () => props.onChange(props.num,value)}
                onChange={ (e) =>  setValue(e.target.value)} />

        </HandTextFieldDiv>
    )
};

HandField.propTypes = {
    hand: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    num: PropTypes.number.isRequired
};

export default HandField;