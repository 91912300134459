import React from 'react'
import styled from 'styled-components'

const GroupWrapper = styled.div`
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  `;

const XButton = styled.button`
    margin: 5px auto;
    display: block;
    width: 50%;
    height: 80%;
    border-radius: 15px;
    border: 3px solid ${props => props.theme.inputBorder};
    padding: 8px 15px;
    background-color: ${props => props.theme.clearButtonBackground};
    
    outline: none;

    color: ${props => props.theme.textColorReverse};

    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.1em;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 20px;
    -webkit-text-shadow: 3px 3px 4px -1px rgba(0,0,0,0.77); 
    text-shadow: 1px 1px rgba(0,0,0,0.1);

  `;

export default (props) => {
    return <GroupWrapper>
            <XButton onClick={() => props.onClick()}>Clear</XButton>
            </GroupWrapper>
}