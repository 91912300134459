
/**
 * Object that holds hand ranges in rows.
 * AA - A2s
 * AKo - K2s
 * ...
 * A2o - 22
 * 
 * Each hand contains a weighting for the percentage slider.
 * 169 rows. So a percentage of 169 can be easily selected. 
 * 50% is 85. Everything less is not selected.
 * 
 * Yep, doing this shit manually.
 * 
 */
const HR = [
    [{ hand: "AA", weight: 169 },
    { hand: "AKs", weight: 166 },
    { hand: "AQs", weight: 161 },
    { hand: "AJs", weight: 159 },
    { hand: "ATs", weight: 153 },
    { hand: "A9s", weight: 152 },
    { hand: "A8s", weight: 149 },
    { hand: "A7s", weight: 142 },
    { hand: "A6s", weight: 141 },
    { hand: "A5s", weight: 139 },
    { hand: "A4s", weight: 136 },
    { hand: "A3s", weight: 134 },
    { hand: "A2s", weight: 133 },
    ],
    [{ hand: "AKo", weight: 163 },
    { hand: "KK", weight: 168 },
    { hand: "KQs", weight: 157 },
    { hand: "KJs", weight: 156 },
    { hand: "KTs", weight: 147 },
    { hand: "K9s", weight: 140 },
    { hand: "K8s", weight: 127 },
    { hand: "K7s", weight: 121 },
    { hand: "K6s", weight: 116 },
    { hand: "K5s", weight: 115 },
    { hand: "K4s", weight: 114 },
    { hand: "K3s", weight: 113 },
    { hand: "K2s", weight: 112 },
    ],
    [{ hand: "AQo", weight: 158 },
    { hand: "KQo", weight: 155 },
    { hand: "QQ", weight: 167 },
    { hand: "QJs", weight: 154 },
    { hand: "QTs", weight: 146 },
    { hand: "Q9s", weight: 138 },
    { hand: "Q8s", weight:  125},
    { hand: "Q7s", weight:  108},
    { hand: "Q6s", weight: 107 },
    { hand: "Q5s", weight: 101 },
    { hand: "Q4s", weight: 97 },
    { hand: "Q3s", weight: 96 },
    { hand: "Q2s", weight: 95 },
    ],
    [{ hand: "AJo", weight: 151 },
    { hand: "KJo", weight: 144 },
    { hand: "QJo", weight: 143 },
    { hand: "JJ", weight: 165 },
    { hand: "JTs", weight: 145 },
    { hand: "J9s", weight: 135 },
    { hand: "J8s", weight:  124 },
    { hand: "J7s", weight: 104 },
    { hand: "J6s", weight: 83 },
    { hand: "J5s", weight: 75 },
    { hand: "J4s", weight: 74 },
    { hand: "J3s", weight: 65 },
    { hand: "J2s", weight: 64 }, 
    ],
    [{ hand: "ATo", weight: 148 },
    { hand: "KTo", weight: 132 },
    { hand: "QTo", weight: 129 },
    { hand: "JTo", weight: 128 },
    { hand: "TT", weight: 164 },
    { hand: "T9s", weight: 133 },
    { hand: "T8s", weight: 123 },
    { hand: "T7s", weight: 103 },
    { hand: "T6s", weight: 84 },
    { hand: "T5s", weight:  73 },
    { hand: "T4s", weight:  55 },
    { hand: "T3s", weight:  54},
    { hand: "T2s", weight:  53 },
    ],
    [{ hand: "A9o", weight: 130 },
    { hand: "K9o", weight: 106 },
    { hand: "Q9o", weight: 105 },
    { hand: "J9o", weight: 102 },
    { hand: "T9o", weight: 100 },
    { hand: "99", weight: 162 },
    { hand: "98s", weight: 122 },
    { hand: "97s", weight: 120 },
    { hand: "96s", weight: 85 },
    { hand: "95s", weight: 46 },
    { hand: "94s", weight: 45 },
    { hand: "93s", weight: 42 },
    { hand: "92s", weight: 41 },
    ],
    [{ hand: "A8o", weight: 126 },
    { hand: "K8o", weight: 87 },
    { hand: "Q8o", weight: 86},
    { hand: "J8o", weight: 83 },
    { hand: "T8o", weight: 82 },
    { hand: "98o", weight: 81 },
    { hand: "88", weight: 160 },
    { hand: "87s", weight: 119 },
    { hand: "86s", weight: 110 },
    { hand: "85s", weight: 44 },
    { hand: "84s", weight: 43 },
    { hand: "83s", weight: 40 },
    { hand: "82s", weight: 39 },
    ],
    [{ hand: "A7o", weight: 111 },
    { hand: "K7o", weight: 78 },
    { hand: "Q7o", weight: 63 },
    { hand: "J7o", weight:  52 },
    { hand: "T7o", weight: 37 },
    { hand: "97o", weight: 36 },
    { hand: "87o", weight: 60 },
    { hand: "77", weight: 150 },
    { hand: "76s", weight: 109 },
    { hand: "75s", weight: 99 },
    { hand: "74s", weight: 69 },
    { hand: "73s", weight: 38 },
    { hand: "72s", weight: 33 },
    ],
    [{ hand: "A6o", weight: 94 },
    { hand: "K6o", weight: 77 },
    { hand: "Q6o", weight: 62 },
    { hand: "J6o", weight: 51 },
    { hand: "T6o", weight: 32 },
    { hand: "96o", weight: 24 },
    { hand: "86o", weight: 20 },
    { hand: "76o", weight: 59 },
    { hand: "66", weight: 137 },
    { hand: "65s", weight: 98 },
    { hand: "64s", weight: 89 },
    { hand: "63s", weight: 68 },
    { hand: "62s", weight: 35 },
    ],
    [{ hand: "A5o", weight: 93 },
    { hand: "K5o", weight: 76 },
    { hand: "Q5o", weight: 61 },
    { hand: "J5o", weight: 50 },
    { hand: "T5o", weight: 28 },
    { hand: "95o", weight: 23 },
    { hand: "85o", weight: 9 },
    { hand: "75o", weight: 19 },
    { hand: "65o", weight: 58 },
    { hand: "55", weight: 131 },
    { hand: "54s", weight: 88 },
    { hand: "53s", weight: 67 },
    { hand: "52s", weight: 35 },
    ],
    [{ hand: "A4o", weight: 92 },
    { hand: "K4o", weight:  72 },
    { hand: "Q4o", weight: 49 },
    { hand: "J4o", weight: 31 },
    { hand: "T4o", weight: 27 },
    { hand: "94o", weight: 22 },
    { hand: "84o", weight: 8 },
    { hand: "74o", weight: 5 },
    { hand: "64o", weight: 18 },
    { hand: "54o", weight: 57 },
    { hand: "44", weight:  118 },
    { hand: "43s", weight: 80 },
    { hand: "42s", weight: 66 },
    ],
    [{ hand: "A3o", weight: 91 },
    { hand: "K3o", weight: 71 },
    { hand: "Q3o", weight: 48 },
    { hand: "J3o", weight: 30 },
    { hand: "T3o", weight: 26 },
    { hand: "93o", weight: 21 },
    { hand: "83o", weight: 7 },
    { hand: "73o", weight: 4 },
    { hand: "63o", weight: 11 },
    { hand: "53o", weight: 14 },
    { hand: "43o", weight: 16 },
    { hand: "33", weight: 118 },
    { hand: "32s", weight: 79 },
    ],
    [{ hand: "A2o", weight: 90 },
    { hand: "K2o", weight: 70 },
    { hand: "Q2o", weight: 47 },
    { hand: "J2o", weight: 29 },
    { hand: "T2o", weight: 25 },
    { hand: "92o", weight: 13 },
    { hand: "82o", weight: 6 },
    { hand: "72o", weight: 2 },
    { hand: "62o", weight: 3 },
    { hand: "52o", weight: 10 },
    { hand: "42o", weight: 12 },
    { hand: "32o", weight: 15 },
    { hand: "22", weight: 117 },
    ],
]




export default HR;