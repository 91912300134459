import React from 'react'
import StyledModal, {StyledH2, StyledP} from '../styled/modal'
import styled from 'styled-components'
import products from '../../images/tooltips/products.png'

const LeftImg = styled.img`
    margin: auto 15px auto 0;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    height: auto;
    width: 40%;
    max-height: 150px;
    z-index: 5;
    float: left;

    @media (max-width: 768px) {
     

    }

    @media (max-width: 414px) {

    }
    `;


export default function one (e) {

    const dems = {
        height: '295px',
        width: '80%',
        left: '50%',
        top: '5%',
        dangerousCSS: 'transform: translate(-50%);'
    }
    return <>
    <StyledModal {...dems}>
        <StyledH2>Welcome to IDC Poker (Beta)!</StyledH2>
        <StyledP>IDC Poker will always be free.</StyledP>
        <StyledP>Planned features include <b>Push/Fold</b> calculator.</StyledP>
        <StyledP>
            <LeftImg src={products} />
            Show support by shopping Products Page!
        </StyledP>

        {e}
    </StyledModal>
    
    </>

}